import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from '../components/ContactForm';


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <h2 id="presentation">Erreur 404 - Page non trouvée</h2>
    <section>
      <p>
        Page non trouvée
      </p>

      <ContactForm />

    </section>

  </Layout>
)

export default NotFoundPage
